<template lang="pug">
.full-box.userwater-main
  .customer-box
    label.blue-header
      span 用户信息
    UsewaterSelect(
      v-if="pageType === 'add'"
      v-model="selectCustomer"
      @change="handleCustomerSelect")
    DataInfo(
      v-if="customerData.id"
      :data="customerData"
      :fields="infoFormFields.customer")
    .ws-empty(v-else) 请选择用户
    label.blue-header(v-if="customerData.id") 结算信息
    DataInfo(
      v-if="customerData.id"
      :data="customerData"
      :fields="infoFormFields.settle")
    label.blue-header(v-if="customerData.id") 开票信息
    DataInfo(
      v-if="customerData.id"
      :data="customerData"
      :fields="infoFormFields.invoice")
  .bgc-block
  .userwater-form-box
    DataformGroup(
      v-if="showForm"
      ref="form"
      v-model="formData"
      buttonFixed
      groupType="row"
      :colspanNum="3"
      :groupSplit="groupSplit"
      :groupTitle="groupTitle"
      :formFields="formFields"
      :buttonList="buttonList")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import { getFormGroupConfig } from '../config'
import DrawerForm from '../../CustomerManage/components/DrawerForm.vue'
import UsewaterSelect from './UsewaterSelect.vue'
import cloneDeep from 'lodash/cloneDeep'
import regexs from '@/utils/regexs'

export default {
  name: 'EditForm',
  components: { DrawerForm, UsewaterSelect },
  props: {
    customerSelectable: {
      type: Boolean,
      default: true
    },
    pageType: {
      type: String,
      default: 'add' // add/edit
    },
    formFields: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    groupFormConfig () {
      return getFormGroupConfig(this.formFields)
    },
    groupSplit () {
      return this.groupFormConfig.split
    },
    groupTitle () {
      return this.groupFormConfig.title
    }
  },
  data () {
    return {
      showForm: false,
      // 选中用户
      selectCustomer: {},

      // 用户信息
      customerData: {},
      // 用户信息配置
      infoFormFields: {
        customer: [
          {
            name: 'customerCode',
            label: '用户编号'
          },
          {
            name: 'customerName',
            label: '用户姓名'
          },
          {
            name: 'phone',
            label: '联系电话'
          },
          {
            name: 'address',
            label: '联系地址'
          },
          {
            name: 'idType',
            label: '证件类型',
            relation: 'idType',
            render: { type: 'select' }
          },
          {
            name: 'idNum',
            label: '证件号码'
          },
          {
            name: 'idAtts',
            label: '证件附件',
            render: { type: 'file', showType: 'image' }
          }
        ],
        settle: [
          {
            name: 'settlementName',
            label: '结算名称'
          },
          {
            name: 'settlementBank',
            label: '结算银行'
          },
          {
            name: 'settlementAccountNo',
            label: '账户号码'
          },
          {
            name: 'settlementPhone',
            label: '联系电话'
          }
        ],
        invoice: [
          {
            name: 'invoiceName',
            label: '开票名称'
          },
          {
            name: 'invoiceBank',
            label: '开票银行'
          },
          {
            name: 'invoiceBankAccount',
            label: '银行账号'
          },
          {
            name: 'invoiceTax',
            label: '纳税人识别号'
          },
          {
            name: 'invoicePhone',
            label: '联系电话'
          }
        ]
      },
      // 表单内容
      formData: {
        meterType: 'NORMAL',
        installNum: 0,
        maxnumber: 99,
        createTime: this.$day().valueOf(),
        imprest: false,
        balance: 0,
        lastReadingNum: 0,
        totalSub: false,
        totalSubType: '',
        totalUsewaterId: '',
        subMeterType: '',
        wastage: null
      },
      // 表单按钮
      buttonList: [{
        name: 'submit',
        label: '保存',
        type: 'primary',
        validate: true,
        func: () => {
          if (!this.formData.customerId) {
            this.$message.warning('请先选择用户')
            return
          }
          return new Promise((resolve, reject) => {
            const meter = this.groupSplit[2].reduce((obj, prop) => {
              obj[prop] = this.formData[prop]
              return obj
            }, {})
            const data = {
              ...this.formData,
              meter
            }
            this.$emit('onsave', { data, resolve, reject })
          })
        }
      }, {
        name: 'closepage',
        label: '关闭',
        func: () => {
          const view = cloneDeep(this.$route)
          this.$router.push({
            name: 'usewaterManage'
          })
          window.globalEvent.emit('delView', view)
        }
      }]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['idType', 'usenature', 'billCycle', 'meterType', 'diameter', 'collections', 'totalSubType', 'subMeterType', 'limitType'])
        .then(res => {
          renderRelationColumns(res, [
            this.infoFormFields.customer,
            this.formFields
          ])
          this.showForm = true
        })
    },
    updateData ({ formData, customerData }) {
      this.$set(this, 'formData', formData)
      this.$set(this, 'customerData', customerData)
      const indexs = ['totalSubType', 'totalUsewaterId', 'subMeterType', 'wastage', 'limitType', 'limitValue'].map(name => this.formFields.findIndex(i => i.name === name))
      const subMeterType = formData.subMeterType
      if (formData.totalSub) {
        // 开启总分表
        // 显示总分表类型
        this.formFields[indexs[0]].form.visabled = true
        this.formFields[indexs[0]].form.rules = [regexs.required]
        // 总分表类型为分表||特殊总表，显示所属总表
        // 总分表类型为分表，显示子表类型
        const showTotalUsewaterId = ['SUB', 'SPEC_TOTAL'].includes(formData.totalSubType) // 是否显示所属总表
        const isSub = formData.totalSubType === 'SUB' // 是否为子表
        const showWastage = ['TOTAL', 'SPEC_TOTAL'].includes(formData.totalSubType) // 是否显示损耗量
        // ---设置 所属总表 显隐和表单规则
        this.formFields[indexs[1]].form.visabled = showTotalUsewaterId
        this.formFields[indexs[1]].form.rules = showTotalUsewaterId ? [regexs.required] : []
        // ---设置 子表类型 显隐和表单规则
        this.formFields[indexs[2]].form.visabled = isSub
        this.formFields[indexs[2]].form.rules = isSub ? [regexs.required] : []
        // ---设置 损耗量 显隐和表单规则
        // 总分表类型为总表 || 特殊总表时，显示损耗量
        this.formFields[indexs[3]].form.visabled = showWastage
        this.formFields[indexs[3]].form.rules = showWastage ? [regexs.required] : []
      } else {
        // 关闭总分表
        indexs.slice(0, 4).forEach(index => {
          this.formFields[index].form.visabled = false
          this.formFields[index].form.rules = []
        })
      }
      // 根据预付费开关决定阈值必填规则
      indexs.slice(4, 6).forEach(index => {
        this.formFields[index].form.rules = formData.imprest ? [regexs.required] : []
      })
      // 编辑初始赋值时，给所属总表赋值后会触发chains联动方法，导致子表分类被重置，因此在初始值赋值结束后重新赋值一次子表类型
      this.$nextTick(() => {
        this.formData.subMeterType = subMeterType
      })
    },
    handleCustomerSelect (customer) {
      this.$set(this, 'customerData', { ...customer })
      this.$set(this, 'formData', Object.assign({}, this.formData, {
        customerId: customer.id,
        usewaterName: customer.customerName,
        usewaterAddress: customer.address,
        usewaterPhone: customer.phone
      }))
      this.formData.totalUsewaterId = null
      this.getTotalUsewaterIdOptions(customer.orgId)
    },
    // 获取总表数据
    getTotalUsewaterIdOptions (orgId) {
      return new Promise((resolve, reject) => {
        this.$get({ url: `/usewater/getCustomerTotalUsewater?orgId=${orgId}` })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            const index = this.formFields.findIndex(item => item.name === 'totalUsewaterId')
            this.formFields[index].form.options = res.data ? res.data.map(item => {
              return { value: item.id, label: `${item.customerName}(${item.customerCode})` }
            }) : []
            resolve()
          })
          .catch(reject)
      })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang="sass" scoped>
.userwater-main
  display: flex
  flex-direction: row
  .customer-box
    width: 260px
    height: 100%
    overflow: auto
    padding: 8px
    background-color: #fff
    .blue-header
      padding: 8px 0
      &:first-of-type
        padding-top: 0
  .bgc-block
    width: 8px
    height: 100%
    background-color: #eceff0
  .userwater-form-box
    position: relative
    flex: 1
    background-color: #fff
    overflow-y: auto
.customer-table-box
  overflow: auto
.ws-empty
  text-align: center
  font-size: 14px
  color: #595959
  margin-top: 12px
.ml8
  margin-left: 8px
</style>

<template lang="pug">
EditForm(
  v-loading="loading"
  ref="editForm"
  pageType="edit"
  :customerSelectable="false"
  :formFields="formFields"
  @onsave="handleEdit"
)
</template>

<script>
import { mapActions } from 'vuex'
import EditForm from '../components/EditForm.vue'
import { composePromise } from '@/utils/common.js'
import { formFieldList } from '../config'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'UsewaterEdit',
  components: { EditForm },
  watch: {
    '$route.query.usewaterId': {
      immediate: true,
      handler (id) {
        if (id && Number(id) !== this.usewaterId) {
          this.usewaterId = Number(id)
          this.getData()
        }
      }
    }
  },
  data () {
    return {
      loading: false,
      formFields: cloneDeep(formFieldList),
      usewaterId: null
    }
  },
  methods: {
    ...mapActions('tagsView', ['delView']),
    // 获取用水户信息
    getUsewaterData () {
      return new Promise((resolve, reject) => {
        if (!this.usewaterId) {
          reject(new Error('error message: missing usewaterId'))
        } else {
          this.$get({
            url: `/usewater/get/${this.usewaterId}`
          })
            .then(res => {
              const meter = res.data.meter
              meter.meterId = meter.id
              delete meter.id
              delete meter.createTime
              delete meter.creatUserId
              delete meter.createUserName
              const usewater = {
                ...res.data,
                ...meter,
                usewaterAttchIds: res.data.usewaterAtts,
                meterAttchIds: meter.meterAtts,
                totalSub: res.data.totalSub || false,
                totalSubType: res.data.totalSubType || null,
                totalUsewaterId: res.data.totalUsewaterId || null
              }
              delete usewater.meter
              resolve(usewater)
            })
            .catch(reject)
        }
      })
    },
    // 获取用户信息
    getCustomerData (usewater) {
      return new Promise((resolve, reject) => {
        if (!usewater.customerId) {
          reject(new Error('error message: missing customerId', usewater))
        } else {
          this.$get({
            url: `/customer/get/${usewater.customerId}`
          })
            .then(customer => resolve({ usewater, customer: customer.data }))
            .catch(reject)
        }
      })
    },
    // 开始赋值
    renderData ({ usewater, customer }) {
      this.$refs.editForm.getTotalUsewaterIdOptions(customer.orgId)
        .then(() => {
          this.$refs.editForm.updateData({
            formData: usewater,
            customerData: customer
          })
          this.loading = false
        })
    },
    getData () {
      const step = [
        this.renderData,
        this.getCustomerData,
        this.getUsewaterData
      ]
      this.loading = true
      composePromise(step)()
        .catch(e => {
          console.error(e)
          this.loading = false
        })
    },
    handleEdit ({ data, resolve, reject }) {
      data.meter.id = data.meterId
      return this.$patch({
        url: `/usewater/update/${this.usewaterId}`,
        data
      })
        .then(res => {
          if (!res) {
            reject('error')
            return
          }
          resolve('success')
          this.$message.success('修改成功!')
          const view = cloneDeep(this.$route)
          this.$router.push({
            path: '/file/usewaterManage'
          })
          window.globalEvent.emit('delView', view)
          this.$nextTick(() => {
            window.globalEvent.emit('renderUsewaterTable')
          })
          // this.delView({ path })
        })
        .catch(reject)
    }
  }
}
</script>
